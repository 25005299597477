<template>
    <y-modal
        ref="modal"
        size="xs"
        @close="reset"
    >
        <template slot="header">
            <div class="title">
                {{ $t('slideshows.delete.title') }}
            </div>
        </template>

        <template slot="body">
            <p>{{ $t('slideshows.delete.message') }}</p>
        </template>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="red"
                    loading-on="submitting-delete-form"
                    @click.prevent.native="destroy"
                >
                    {{ $t('button.delete') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';

    export default {
        name: 'DeleteModal',

        components: {
            YModal,
        },

        props: {
            /**
             * Array of slides
             */
            items: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id         : null,
                index      : null,
                isPublished: null,
            };
        },

        methods: {
            /**
             * Open delete modal
             *
             * @param payload
             * @param isPublished
             */
            open(payload, isPublished) {
                this.id = payload.id;
                this.index = payload.index;
                this.isPublished = isPublished;
                this.$refs.modal.open();
            },

            /**
             * Close delete modal
             */
            close() {
                this.$wait.end('submitting-delete-form');
                this.$refs.modal.close();
                this.reset();
            },

            /**
             * Reset delete modal
             */
            reset() {
                this.id = null;
                this.index = null;
                this.isPublished = null;
            },

            /**
             * Destroy slide
             */
            destroy() {
                this.$wait.start('submitting-delete-form');
                if (!this.isPublished) {
                    this.$toast.success(this.$t('messages.slideshow.destroy.success'));
                    this.$emit('destroy-with-id', this.id);
                    return this.close();
                }

                return this.$services.destroy('content-post', this.id).then(() => {
                    this.$toast.success(this.$t('messages.slideshow.destroy.success'));
                    this.$emit('destroy-with-id', this.id);
                    return this.close();
                }).catch((error) => {
                    this.handleError(error);
                    this.$wait.end('submitting-delete-form');
                });
            },
        },

    };
</script>
