<template>
    <y-modal
        ref="previewModal"
        :title="$t('slideshows.preview')"
        size="lg"
        forced
    >
        <div
            v-if="show"
            slot="body"
        >
            <y-slideshow
                nav="3"
                height="50vh"
                :autoplay="!!settings.autoplay"
                :loop="!!settings.loop"
                :space-between="Number(settings.space_between)"
                :slides-per-view="Number(settings.slides_per_view)"
                :effect="settings.effect"
                :direction="settings.direction"
                :speed="settings.speed"
                :delay="settings.transition"
                :disable-on-interaction="!!settings.pause_on_hover"
            >
                <y-slide
                    v-for="item in items"
                    :key="item.id"
                    position="center-center"
                    class="slideshow pos-r"
                >
                    <img
                        v-if="item.slideModel"
                        :src="item.slideModel.link"
                        class="w-100 h-100"
                    >
                    <div
                        v-if="item.has_content"
                        class="slideshow-preview__content pos-a p10 w-100"
                        :class="[direction]"
                    >
                        <h2
                            v-if="item.title"
                            class="title mb30 fz-xl fw-b color-white"
                        >
                            {{ item.title }}
                        </h2>
                        <h3
                            v-if="item.title2"
                            class="mb30 fz-lg fw-b color-white"
                        >
                            {{ item.title2 }}
                        </h3>
                        <p
                            v-if="item.text"
                            class="text fz-md fw-md color-white"
                        >
                            {{ item.text }}
                        </p>
                        <div
                            v-if="item.has_button"
                            class="d-f justify-content-end"
                        >
                            <y-button
                                class="ml10"
                                :to="item.button_link"
                            >
                                {{ item.button_title }}
                            </y-button>
                        </div>
                    </div>
                </y-slide>
            </y-slideshow>
            <y-form-alert
                class="mt10"
                color="red"
                :content="$t('slideshows.style_differences')"
            />
        </div>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { YModal, YSlideshow, YSlide } from '@deps';
    import { YFormAlert } from '@deps/form/elements/Alert';

    export default {

        name: 'SlideshowPreview',

        components: {
            YModal,
            YSlideshow,
            YSlide,
            YFormAlert,
        },

        /**
         * 
         */
        data() {
            return {
                items    : null,
                settings : null,
                show     : false,
                direction: null,
            };
        },

        methods: {

            /**
             * Open modal
             *
             * @param params
             */
            open(params) {
                this.items = params.items;
                this.settings = params.settings;
                this.show = true;
                this.direction = this.$configs.locales.options.find((locale) => locale.code === params.locale).dir;
                this.$refs.previewModal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.items = null;
                this.settings = null;
                this.show = false;
                this.direction = null;
                this.$refs.previewModal.close();
            },

        },

    };

</script>
