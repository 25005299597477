<template>
    <y-row v-if="model && form">
        <!-- Main area -->
        <y-col size="8">
            <template v-for="tab in tabs">
                <div
                    v-if="showTabs.includes(tab.id)"
                    v-show="activeTab === tab.id"
                    :key="`tab-content-${tab.id}`"
                    class="tab-content"
                >
                    <y-form
                        v-if="tab.form && tab.form.length"
                        v-model="model"
                        :params="content(tab.form)"
                    />
                </div>
            </template>
        </y-col>

        <!-- Side area -->
        <y-col size="4">
            <!-- Buttons -->
            <y-post-buttons
                v-if="buttons && buttons.length"
                :form="form"
                @action="action"
            />

            <y-form
                v-if="form.side && form.side.length"
                v-model="model"
                :params="form.side"
            />
        </y-col>
    </y-row>
</template>

<script>

    // Components
    import { YForm } from '@deps';
    import YPostButtons from '../PostButtons';

    export default {

        name: 'PostForm',

        components: {
            YForm,
            YPostButtons,
        },

        props: {
            /**
             * Form object
             */
            form: Object,

            /**
             * Content type details
             */
            type: Object,

            /**
             * Value of form
             */
            value: Object,

            /**
             * Active tab
             */
            activeTab: [Number, String],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model   : this.value || {},
                showTabs: [],
            };
        },

        computed: {

            /**
             * Return buttons of form
             *
             * @returns {*}
             */
            buttons() {
                return this.get(this.form, 'buttons');
            },

            /**
             * Return tabs of form
             */
            tabs() {
                return this.get(this.form, 'tabs');
            },

        },

        watch: {
            /**
             * Watch model and emit
             */
            model() {
                this.$emit('input', this.model);
            },

            /**
             * Watch value and emit
             */
            value() {
                this.model = this.value;
            },

            /**
             * Show tabs first time
             */
            activeTab() {
                this.addActiveTab(this.activeTab);
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.form.tabs.forEach((tab) => {
                if (!tab.form.find((item) => item.component_name === 'map')) {
                    this.addActiveTab(tab.id);
                }
            });
        },

        methods: {

            /**
             * Run the action
             *
             * @param type
             */
            action(type) {
                this.$emit('action', type);
            },

            /**
             * Add activeTab to show tabs list
             *
             * @param activeTab
             */
            addActiveTab(activeTab) {
                if (!this.showTabs.includes(activeTab)) {
                    this.showTabs.push(activeTab);
                }
            },

            /**
             * Tabs content
             *
             * @param params {Array}
             * @returns {Array}
             */
            content(params) {
                Object.keys(params).forEach((key) => {
                    if (params[key].component_name === 'tagging') {
                        // eslint-disable-next-line no-param-reassign
                        params[key].data = this.tagging;
                    } else if (params[key].component_name === 'post-category-picker') {
                        // eslint-disable-next-line no-param-reassign
                        params[key].data = this.postCategory;
                    } else if (params[key].component_name === 'post-tag-picker') {
                        // eslint-disable-next-line no-param-reassign
                        params[key].data = this.postTag;
                    }
                });
                return params;
            },
        },

    };
</script>
