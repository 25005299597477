/*
*-------------------------------------------------------
* Array Helper Functions
*-------------------------------------------------------
*/

/**
 * Returns true if the provided predicate function returns true for all elements in a collection, false otherwise.
 * Omit the second argument, fn, to use Boolean as a default.
 *
 * @param {Array} arr
 * @param {*} fn
 * @returns {boolean}
 */
export const all = (arr, fn = Boolean) => arr.every(fn);


/**
 * Check if all elements in an array are equal.
 *
 * @param {Array} arr
 * @returns {*|boolean}
 */
export const allEqual = (arr) => arr.every((val) => val === arr[0]);


/**
 * Returns true if the provided predicate function returns true for at least one element in a collection,
 * false otherwise.
 * Omit the second argument, fn, to use Boolean as a default.
 *
 * @param {Array} arr
 * @param {*} fn
 * @returns {*|boolean}
 */
export const any = (arr, fn = Boolean) => arr.some(fn);


/**
 * Returns true if the provided predicate function returns false for all elements in a collection, false otherwise.
 * Omit the second argument, fn, to use Boolean as a default.
 *
 * @param {Array} arr
 * @param {*} fn
 * @returns {boolean}
 */
export const none = (arr, fn = Boolean) => !arr.some(fn);


/**
 * Splits values into two groups according to a predicate function,
 * which specifies which group an element in the input collection belongs to.
 * If the predicate function returns a truthy value, the collection element belongs to the first group;
 * otherwise, it belongs to the second group.
 *
 * @param {Array} arr
 * @param {*} fn
 * @returns {*}
 */
export const bifurcateBy = (arr, fn) => arr.reduce((acc, val, i) => (acc[fn(val, i) ? 0 : 1].push(val), acc), [[], []]); // eslint-disable-line


/**
 * Chunks an array into smaller arrays of a specified size.
 *
 * @param {Array} arr
 * @param {*} size
 * @returns {any[]}
 */
export const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));


/**
 * Removes falsey values (false, null, 0, "", undefined, and NaN) from an array.
 *
 * @param {Array} arr
 * @returns {*}
 */
export const compact = (arr) => arr.filter(Boolean);


/**
 * Returns the difference between two arrays.
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {*}
 */
export const difference = (a, b) => {
    const s = new Set(b);
    return a.filter((x) => !s.has(x));
};


/**
 * Returns the symmetric difference between two arrays, without filtering out duplicate values.
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {*}
 */
export const symmetricDifference = (a, b) => {
    const sA = new Set(a);
    const sB = new Set(b);
    return [...a.filter((x) => !sB.has(x)), ...b.filter((x) => !sA.has(x))];
};


/**
 * Deep flattens an array.
 *
 * @param {Array} arr
 * @returns {*}
 */
export const deepFlatten = (arr) => [].concat(...arr.map((v) => (Array.isArray(v) ? deepFlatten(v) : v)));


/**
 * Flattens an array up to the specified depth.
 * Omit the second argument, depth to flatten only to a depth of 1 (single flatten).
 *
 * @param {Array} arr
 * @param {number} depth
 * @returns {*}
 */
export const flatten = (arr, depth = 1) => arr.reduce((a, v) => a.concat(depth > 1 && Array.isArray(v) ? flatten(v, depth - 1) : v), []);


/**
 * Groups the elements of an array based on the given function.
 *
 * @param {Array} arr
 * @param {*} fn
 * @returns {*}
 */
export const groupBy = (arr, fn) => arr.map(typeof fn === 'function' ? fn : (val) => val[fn]).reduce((acc, val, i) => {
    acc[val] = (acc[val] || []).concat(arr[i]);
    return acc;
}, {});


/**
 * Returns all indices of val in an array. If val never occurs, returns [].
 *
 * @param {Array} arr
 * @param {*} val
 * @returns {*}
 */
export const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);


/**
 * Returns a list of elements that exist in both arrays.
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {*}
 */
export const intersection = (a, b) => {
    const s = new Set(b);
    return a.filter((x) => s.has(x));
};


/**
 * Maps the values of an array to an object using a function, where the key-value pairs consist of
 * the original value as the key and the mapped value.
 *
 * @param {Array} arr
 * @param {*} fn
 * @returns {*}
 */
export const mapObject = (arr, fn) => (a => ( // eslint-disable-line
    (a = [arr, arr.map(fn)]), a[0].reduce((acc, val, ind) => ((acc[val] = a[1][ind]), acc), {}) // eslint-disable-line
))();


/**
 * Groups the elements into two arrays, depending on the provided function's truthiness for each element.
 *
 * @param {Array} arr
 * @param {*} fn
 * @returns {*}
 */
export const partition = (arr, fn) => arr.reduce(
    (acc, val, i, newArr) => {
        acc[fn(val, i, newArr) ? 0 : 1].push(val);
        return acc;
    },
    [[], []],
);


/**
 * Reduces a given Array-like into a value hash (keyed data store).
 *
 * @param {object} object
 * @param {*} key
 * @returns {*}
 */
export const toHash = (object, key) => Array.prototype.reduce.call(
    object,
    (acc, data, index) => ((acc[!key ? index : data[key]] = data), acc), // eslint-disable-line no-return-assign,no-sequences
    {},
);


/**
 * Reverse array items.
 *
 * @param {Array} array
 * @returns {*}
 */
export const reverseArray = (array) => array.slice(0).reverse();


/**
 * Group Array of JavaScript Objects by Key or Property Value
 *
 * @param {*} key
 * @returns {*}
 */
export const groupByKey = (key) => (array) => array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj); // eslint-disable-line no-param-reassign
    return objectsByKeyValue;
}, {});
