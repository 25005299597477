<template>
    <div>
        <!-- Page Head -->
        <y-page-head
            icon="md-comment-question"
            :title="title"
        >
            <template slot="actions">
                <y-button
                    v-if="permissions && permissions.can_create"
                    class="color-blue"
                    disabled-on="submitting-form"
                    @click.native.prevent="$refs.FaqModal.open()"
                >
                    {{ $t('button.add_question') }}
                </y-button>
            </template>

            <div
                v-if="tabs && tabs.length > 1"
                slot="tabs"
            >
                <a
                    v-for="tab in tabs"
                    :key="`faq-tab-${tab.code}`"
                    href="#"
                    :class="{active: tab.code === activeTab}"
                    @click.prevent="changeTab(tab.code)"
                >
                    {{ tab.title }}
                </a>
            </div>
        </y-page-head>
        <!-- Page Head -->

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <div v-if="labels && permissions">
                <main>
                    <div
                        class="row"
                        :class="[{disabled: $wait.is('submitting-form')}]"
                    >
                        <!-- Sidebar -->
                        <div class="col-sm-4">
                            <y-sidebar
                                :labels="labels"
                                :folders="folders"
                                :permissions="permissions"
                            />
                        </div>

                        <!-- Items -->
                        <div class="col-sm-8">
                            <y-panel
                                v-for="label in labels"
                                :id="`group-item-${label.id}`"
                                :key="`group-item-${label.id}`"
                                :title="label.title"
                                icon="md-folder-outline"
                                collapsible
                            >
                                <draggable
                                    v-if="model.items[label.id || null] && model.items[label.id || null].length"
                                    v-bind="dragOptions"
                                    ref="Draggable"
                                    handle=".slide-item header .md-menu"
                                    class="m0 p0"
                                    tag="ul"
                                    :list="model.items[label.id || null]"
                                    :group="{ name: `g-${label.id}` }"
                                    @sort="saveSort(label.id)"
                                >
                                    <y-faq-item
                                        v-for="item in model.items[label.id || null]"
                                        :key="`faq-item-${item.id}`"
                                        :item="item"
                                        :permissions="permissions"
                                        @edit="$refs.FaqModal.open($event)"
                                        @delete="$refs.DeleteModal.open(item)"
                                    />
                                </draggable>

                                <!-- Empty state -->
                                <y-empty-state
                                    v-else
                                    :title="$t('faqs.title')"
                                    no-image
                                    :height="60"
                                    class="mb0"
                                />
                            </y-panel>
                        </div>
                    </div>
                </main>
            </div>
        </y-loading>

        <!-- Modals -->
        <y-faq-modal
            ref="FaqModal"
            :labels="labels"
            :items="model.items"
            :locale="activeTab"
            @done="reFetch"
        />

        <y-delete-modal
            ref="DeleteModal"
            model="content-post"
            :type="$t('faqs.type')"
            @done="reFetch"
        />
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';

    import { groupByKey } from '@helpers/array';

    import Draggable from 'vuedraggable';

    import YEmptyState from '@deps/EmptyState';
    import YFaqItem from '@/modules/content-themes/components/faqs/FaqItem';
    import YSidebar from '@/modules/content-themes/components/faqs/Sidebar';
    import YFaqModal from '@/modules/content-themes/components/faqs/FaqModal';
    import YDeleteModal from '@deps/DeleteModal';

    export default {

        name: 'FaqsManage',

        components: {
            Draggable,
            YEmptyState,
            YFaqItem,
            YSidebar,
            YFaqModal,
            YDeleteModal,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('faqs.title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                title: this.$t('faqs.title'),

                groupBy: groupByKey('label'),

                model: {},

                locales    : null,
                activeTab  : null,
                tabs       : [],
                tabsCreated: false,

                labels     : null,
                items      : null,
                permissions: null,
                folders    : null,
            };
        },

        computed: {
            /**
             * @inheritDoc
             */
            dragOptions() {
                return {
                    animation : 200,
                    ghostClass: 'ghost',
                };
            },

        },

        methods: {

            /**
             * Change tab
             *
             * @param tab
             */
            changeTab(tab) {
                if (this.activeTab === tab) {
                    return;
                }

                this.$set(this, 'activeTab', tab);
                this.reFetch();
            },

            /**
             * Fetch
             */
            fetch() {
                const params = {
                    id             : this.$route.params.id,
                    lang           : this.activeTab || null,
                    organization_id: this.$route.query.organization_id || null,
                };
                return this.$services.ContentThemes.faqFetch(params).then((response) => {
                    this.makeData(response.data);
                }).catch((error) => {
                    this.handleError(error);
                    this.$router.push({ name: 'dashboard' });
                });
            },

            /**
             * Make data of fetch
             *
             * @param data
             */
            makeData(data) {
                this.title = data.metadata.type || this.$t('faqs.title');

                if (data.metadata.organization_name) {
                    this.title = `${this.title}: ${data.metadata.organization_name}`;
                }

                this.locales = data.metadata.locales;
                this.permissions = data.metadata.permissions;
                this.folders = data.metadata.folders;
                if (!this.tabsCreated) {
                    this.createTabs();
                }

                if (!this.activeTab) {
                    this.activeTab = this.tabs[0].code;
                }

                this.labels = [
                    ...data.results.labels,
                    {
                        id   : 0,
                        title: this.$t('faqs.uncategorized'),
                    },
                ];
                this.items = data.results.items;
                this.$set(this.model, 'items', this.groupBy(this.items, 'label'));
            },

            /**
             * Create Locale Tabs
             */
            createTabs() {
                Object.keys(this.locales).forEach((key) => {
                    Object.keys(this.$config('locales.options')).forEach((k) => {
                        if (this.locales[key] === this.$config('locales.options')[k].code) {
                            this.tabs.push(this.$config('locales.options')[k]);
                        }
                    });
                });
                this.tabsCreated = true;
            },

            /**
             * Save sort
             *
             * @param label
             */
            saveSort(label) {
                const items = {
                    [label || null]: this.model.items[label || null],
                };
                const params = {
                    type_id        : this.$route.params.id,
                    lang           : this.activeTab || null,
                    organization_id: this.$route.query.organization || null,
                    items,
                };
                return this.$services.ContentThemes.faqSaveOrder(params).then(() => {
                    this.$toast.success(this.$t('faqs.messages.sort'));
                    this.fetch();
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },

    };
</script>
