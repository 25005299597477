<template>
    <y-modal ref="Modal">
        <div slot="body">
            <p
                class="mb0"
                v-html="$t(`content.confirm.${type}`)"
            />
        </div>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="orange"
                    @click.prevent.native="confirm"
                >
                    {{ $t('button.sure') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';

    export default {
        name: 'PostConfirmModal',

        components: {
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                type: null,
            };
        },

        methods: {

            /**
             * Remove item
             */
            confirm() {
                this.$emit('confirm', this.type);
                this.close();
            },

            /**
             * Open modal
             *
             * @param type
             */
            open(type) {
                this.type = type;
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.type = null;
            },

        },
    };
</script>
