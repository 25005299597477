<template>
    <y-loading
        class="panel-grid-layout"
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <y-page-head
            :title="$t(`content.post.${type}`)"
            icon="md-text-box-outline"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />
            </template>

            <!-- Tabs -->
            <template slot="tabs">
                <a
                    v-for="tab in sortedTabs"
                    :key="`tab-title-${tab.id}`"
                    href="#"
                    :class="{active: activeTab === tab.id}"
                    @click.prevent="changeTab(tab.id)"
                >
                    <i
                        v-if="tab.icon"
                        class="icon"
                        :class="[tab.icon]"
                    />
                    {{ tab.label }}
                </a>
            </template>
        </y-page-head>

        <main>
            <form
                id="content_post"
                @submit.prevent="submit(save)"
            >
                <y-post-form
                    v-model="model"
                    :form="form"
                    :active-tab="activeTab"
                    @action="handleSubmit"
                />
            </form>
        </main>
    </y-loading>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YPostForm from '@/modules/content/components/PostForm';

    export default {
        name: 'ContentPostEdit',

        components: {
            YPostForm,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t(`content.post.${this.type}`),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model     : null,
                form      : null,
                submitType: null,
                sortedTabs: null,
                activeTab : null,
            };
        },

        computed: {

            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Is clone
             */
            isClone() {
                return !!this.$route.query.clone;
            },

            /**
             * Check if is edit mode
             */
            type() {
                return this.isEdit ? 'edit' : 'create';
            },

            /**
             * Return type if content
             */
            contentType() {
                return this.$route.params.type;
            },

            /**
             * Return lang of post
             */
            lang() {
                return this.$route.query.lang;
            },

            /**
             * Return sisterhood of post
             */
            sisterhood() {
                return this.$route.query.sisterhood;
            },

            /**
             * Breadcrumb of the page
             */
            breadcrumbs() {
                return [
                    this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                    this.$bc(this.$t(`breadcrumbs.content.post.${this.isEdit ? 'edit' : 'create'}`)),
                ];
            },

        },

        methods: {

            /**
             * Fetch content type form and value
             */
            fetch() {
                const params = {
                    id        : this.$route.params.id || this.$route.query.clone || null,
                    clone     : this.isClone ? 1 : null,
                    type      : this.$route.params.type,
                    lang      : this.lang,
                    sisterhood: this.sisterhood,
                };
                return this.$services.Content.postFetch(params).then((response) => {
                    const model = response.data.results;

                    if (this.isClone) {
                        delete model.id;
                    }

                    if (this.lang || this.sisterhood) {
                        model.lang = this.lang;
                        model.sisterhood = this.sisterhood;
                    }

                    this.$set(this, 'model', model);
                    this.$set(this, 'form', response.data.metadata.form);
                    if (this.form) {
                        this.$set(this, 'activeTab', this.get(this.form.tabs[0], 'id'));
                    }
                    if (this.form && this.form.tabs) {
                        this.sortTabs();
                    }
                    this.$set(this.form, 'published_at', response.data.results.published_at);
                    this.$set(this.form, 'published_by', response.data.results.published_by);
                    this.setBreadcrumb();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Set page's breadcrumb in store
             */
            setBreadcrumb() {
                this.$store.dispatch('breadcrumbs/set', this.breadcrumbs);
            },

            /**
             * Handle submit content post form
             *
             * @param payload
             */
            handleSubmit(payload) {
                this.submitType = payload.type;

                if (payload.published_at) {
                    this.model.published_at = payload.published_at;
                }
                if (payload.moderation_note) {
                    this.model.moderation_note = payload.moderation_note;
                }

                this.save();
            },

            /**
             * Save content type form
             *
             * @returns {*}
             */
            save() {
                const params = {
                    ...this.model,
                    _submit: this.submitType,
                    type   : this.$route.params.type,
                };
                this.$wait.start('content-submitting');
                this.$wait.start(`content-submitting-${this.submitType}`);
                return this.$services.Content.postSave(params).then((response) => {
                    this.$toast.success(this.$t(`content.success.${this.submitType}`));
                    this.handleAfterSave(response);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('content-submitting');
                    this.$wait.end(`content-submitting-${this.submitType}`);
                });
            },

            /**
             * Refresh buttons
             */
            refreshButtons() {
                this.$wait.start('content-refresh-buttons');
                const params = {
                    id: this.$route.params.id,
                };
                this.$services.Content.postFetchButtons(params).then((response) => {
                    this.$set(this, 'form', { ...this.form, ...response.data.metadata.form });
                    this.$set(this.form, 'published_at', response.data.results.published_at);
                    this.$set(this.form, 'published_by', response.data.results.published_by);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('content-refresh-buttons'));
            },

            /**
             * Handle after save action
             *
             * @param response
             */
            handleAfterSave(response) {
                if (this.submitType === 'delete') {
                    this.redirectToList();
                } else if (!this.isEdit) {
                    this.redirectToEdit(response.data.metadata.hashid);
                } else {
                    this.refreshButtons();
                }
            },

            /**
             * Redirect to the list of posts
             */
            redirectToList() {
                this.$router.push({
                    name  : 'content-post-list',
                    params: {
                        type: this.contentType,
                    },
                });
            },

            /**
             * Redirect to the list of posts
             *
             * @param id
             */
            redirectToEdit(id) {
                this.$router.push({
                    name  : 'content-post-edit',
                    params: {
                        type: this.contentType,
                        id,
                    },
                });
            },

            /**
             * Change tab
             *
             * @param tab
             */
            changeTab(tab) {
                this.activeTab = tab;
            },

            /**
             * Sort Tabs
             */
            sortTabs() {
                const sorted = this.form.tabs.sort((a, b) => {
                    if ( a.order < b.order ) {
                        return -1;
                    }
                    if ( a.order > b.order ) {
                        return 1;
                    }
                    return 0;
                });
                this.$set(this, 'sortedTabs', sorted);
            },
        },

    };
</script>
