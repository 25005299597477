<template>
    <y-modal
        ref="Modal"
        :title="title"
        @close="reset"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('loading-post')"
                height="200px"
            >
                <form
                    id="faq-form"
                    @submit.prevent="submit(save)"
                >
                    <y-form-field
                        v-model="model.title"
                        type="text"
                        name="title"
                        validation="required"
                    />
                    <y-form-editor
                        v-model="model.text"
                        name="text"
                        validation="required"
                        toolbar="mini"
                    />
                    <y-form-field
                        v-model="model.label"
                        type="select"
                        name="category"
                        :options="labels"
                        label-field="title"
                        value-field="id"
                    />
                </form>
            </y-loading>
        </div>

        <template
            v-if="!$wait.is('loading-post')"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="blue"
                    form="faq-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YModal, YFormField, YFormEditor } from '@deps';

    export default {
        name: 'FaqModal',

        components: {
            YModal,
            YFormField,
            YFormEditor,
        },

        mixins: [FormMixin],

        props: {

            /**
             * Array of labels
             */
            labels: Array,

            /**
             * Current language
             */
            locale: String,

            /**
             * Items
             */
            items: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id   : null,
                model: {
                    label: 0,
                },
            };
        },

        computed: {
            /**
             * Return modal title
             */
            title() {
                return this.id ? this.$t('faqs.edit_modal') : this.$t('faqs.create_modal');
            },

            /**
             * Return order of new post
             */
            order() {
                if (!(this.id || this.items)) {
                    return 0;
                }
                if ((!this.id && this.items)) {
                    return 0;
                }
                return this.get(this.items, this.model.label || null, '[]').find((item) => item.id === this.id)?.order || 0;
            },
        },

        methods: {

            /**
             * Save form
             */
            fetch() {
                this.$wait.start('loading-post');

                const including = [
                    'title',
                    'text',
                    'tagging_labels_ids',
                    'deleted_at',
                ];
                this.$services.single('ContentPost', this.id, {
                    with_trashed: 1,
                    including,
                }).then((response) => {
                    this.$set(this, 'model', {
                        id   : response.data.results.id,
                        title: response.data.results.title,
                        text : response.data.results.text,
                        label: this.get(response.data.results.tagging_labels_ids, 0, 0),
                    });
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                }).finally(() => this.$wait.end('loading-post'));
            },

            /**
             * Save form
             */
            save() {
                const params = {
                    type_id        : this.$route.params.id,
                    id             : this.id,
                    locale         : this.locale,
                    organization_id: this.$route.query.organization_id || null,
                    order          : this.order,
                    ...this.model,
                };
                params.label = params.label || null;
                return this.$services.ContentThemes.faqSave(params).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('faqs.type') }));
                    this.$emit('done', this.model.label);
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Open modal
             *
             * @param id
             */
            open(id = null) {
                if (id) {
                    this.id = id;
                    this.fetch();
                }
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.id = null;
                this.model = {
                    label: 0,
                };
            },

        },
    };
</script>
