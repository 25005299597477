<template>
    <y-panel
        :title="truncate(item.title)"
        :subtitle="subtitle"
        :icon="permissions.can_edit ? 'md-menu' : null"
        class="slide-item faq-item"
    >
        <template slot="functions">
            <y-form-field
                v-if="active ? permissions.can_trash : permissions.can_restore"
                v-model="active"
                type="checkbox"
                :name="`active-${item.id}`"
                size="sm"
                class="mb0"
                switch
                circular
                color="green"
                no-label
                @click.native.prevent="changeStatus"
            />

            <y-button
                v-if="active && permissions.can_edit"
                key="up"
                size="xs"
                class="color-green"
                @click.native.prevent="$emit('edit', item.id)"
            >
                {{ $t('button.edit') }}
            </y-button>

            <y-button
                v-if="permissions.can_delete"
                key="del"
                size="xs"
                class="color-red"
                @click.native.prevent="$emit('delete', item)"
            >
                {{ $t('button.delete') }}
            </y-button>
        </template>
    </y-panel>
</template>

<script>
    import { truncate, generateId } from '@nodes/helpers/string';
    import { YFormField } from '@deps';

    export default {

        name: 'FaqItem',

        components: {
            YFormField,
        },

        props: {
            /**
             * Item
             */
            item: Object,

            /**
             * Permissions
             */
            permissions: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                active   : this.item.active ? 1 : 0,
                keyHelper: generateId(),
            };
        },

        computed: {
            /**
             * Subtitle of item
             */
            subtitle() {
                return this.active ? '' : `(${this.$t('general.inactive')})`;
            },

            /**
             * Check item is in loading mode
             */
            isLoading() {
                return this.$wait.is(`faq-item-${this.keyHelper}`);
            },
        },

        methods: {

            /**
             *
             */
            startLoading() {
                this.$wait.start(`faq-item-${this.keyHelper}`);
            },

            /**
             *
             */
            endLoading() {
                this.$wait.end(`faq-item-${this.keyHelper}`);
            },

            /**
             * Change status of item
             */
            changeStatus() {
                const type = this.active ? 'trash' : 'restore';
                this.startLoading();
                this.$services[type]('content-post', this.item.id).then(() => {
                    this.active = !this.active;
                    this.$toast.success(this.$t(`messages.faqs.${type}.success`));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.endLoading());
            },

            truncate,

        },

    };
</script>
