<template>
    <y-loading
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <y-page-head
            :title="$t('slideshows.page_title')"
            icon="md-folder-multiple-image"
        >
            <template slot="actions">
                <y-button
                    v-if="permissions[activeTab] && (permissions[activeTab].can_edit || permissions[activeTab].can_create)"
                    color="blue"
                    form="main-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
                <y-button
                    :disabled="!model.items || !model.items.length"
                    @click.native.prevent="showPreview"
                >
                    {{ $t('slideshows.preview') }}
                </y-button>
                <y-button
                    v-if="permissions[activeTab] && permissions[activeTab].can_create"
                    @click.native.prevent="addItem"
                >
                    {{ $t('button.create') }}
                </y-button>
            </template>
            <template slot="tabs">
                <a
                    v-for="tab in tabs"
                    :key="`slider-tab-${tab.id}`"
                    :href="'#'"
                    :class="{active: tab.id === activeTab}"
                    @click.prevent="onChangeTab(tab.id)"
                >
                    {{ $t(`general.locales.${tab.title}`) }}
                </a>
            </template>
        </y-page-head>

        <main>
            <form
                id="main-form"
                @submit.prevent="submit(save)"
            >
                <y-row>
                    <y-col size="8">
                        <draggable
                            v-if="model.items"
                            v-bind="dragOptions"
                            ref="Draggable"
                            handle=".slide-item header .md-menu"
                            class="m0 p0"
                            tag="ul"
                            :list="model.items"
                            :group="{ name: 'g1' }"
                        >
                            <template v-for="(item, index) in model.items">
                                <y-slideshow-item
                                    v-if="item.locale === activeTab"
                                    :key="`slideshow-item-${item.id}`"
                                    v-model="model.items[index]"
                                    :index="index"
                                    :permissions="permissions[activeTab]"
                                    @delete="openDeleteModal"
                                />
                            </template>
                        </draggable>

                        <y-empty-state
                            v-else
                            class="mb0"
                            :message="$t('slideshows.empty')"
                            height="400"
                        />
                    </y-col>
                    <y-col size="4">
                        <y-slideshow-settings
                            :key="activeTab"
                            v-model="model.settings[activeTab]"
                            :permissions="permissions[activeTab]"
                            :directions="directionOptions"
                            :effects="effectOptions"
                        />
                    </y-col>
                </y-row>
            </form>

            <!-- Slideshow Preview -->
            <y-slideshow-preview
                v-if="model.items && model.items.length"
                ref="previewModal"
            />

            <!-- Slideshow Preview -->
            <y-slideshow-delete-modal
                v-if="model.items && model.items.length"
                ref="deleteModal"
                :items="model.items"
                @destroy-with-id="doDelete"
                @refresh="reFetch"
            />
        </main>
    </y-loading>
</template>

<script>
    import Draggable from 'vuedraggable';
    import { generateId } from '@nodes/helpers/string';

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YEmptyState from '@deps/EmptyState';

    import YSlideshowItem from '@/modules/content-themes/components/slideshows/Item';
    import YSlideshowSettings from '@/modules/content-themes/components/slideshows/Settings';
    import YSlideshowPreview from '@/modules/content-themes/components/slideshows/Preview';
    import YSlideshowDeleteModal from '@/modules/content-themes/components/slideshows/DeleteModal';

    export default {

        name: 'ManageSlideshow',

        components: {
            Draggable,
            YEmptyState,
            YSlideshowItem,
            YSlideshowSettings,
            YSlideshowPreview,
            YSlideshowDeleteModal,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.slideshows.edit')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.pageTitle || this.$t('slideshows.page_title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    settings: {},
                    items   : null,
                },

                permissions: {},
                locales    : [],
                tabs       : [],
                activeTab  : null,

                directionOptions: null,
                effectOptions   : null,
            };
        },

        computed: {
            /**
             * @inheritDoc
             */
            dragOptions() {
                return {
                    animation : 200,
                    ghostClass: 'ghost',
                };
            },

            /**
             * Return id
             */
            id() {
                return this.$route.params.id;
            },

            /**
             * return an array of slides accordin to the current active locale
             */
            currentLocaleSlides() {
                if (this.model.items && this.model.items.length) {
                    return this.model.items?.filter(((slide) => slide.locale === this.activeTab));
                }
                return [];
            },
        },

        methods: {

            /**
             * @inheritDoc
             */
            fetch() {
                this.$wait.start('loading-page');
                return this.$services.ContentThemes.slideshowsFetch({ id: this.id })
                    .then((response) => {
                        const { results, metadata } = response.data;

                        const items = results.items.map((slide) => {
                            const item = { ...slide };
                            const slideImage = item['slide-image'] || undefined;
                            if (slideImage && typeof slideImage === 'object') {
                                item.slideModel = { ...slideImage };
                                item.slide = [slideImage.id];
                            }
                            return item;
                        });

                        this.pageTitle = metadata.page_title;
                        this.permissions = metadata.permissions;
                        if (!Array.isArray(results.settings)) {
                            this.$set(this.model, 'settings', results.settings);
                        }
                        this.model.items = items;
                        this.locales = metadata.locales;

                        this.directionOptions = metadata.settings.direction;
                        this.effectOptions = metadata.settings.effect;
                        this.initSettings();
                        this.generateTabs();
                    })
                    .catch((error) => this.handleError(error))
                    .finally(() => {
                        this.$wait.end('loading-page');
                    });
            },

            /**
             * Add new slide item
             */
            addItem() {
                const item = {
                    id             : generateId(),
                    new            : 1,
                    active         : 1,
                    has_content    : 0,
                    has_button     : 0,
                    title          : '',
                    title2         : '',
                    text           : '',
                    button_title   : '',
                    button_link    : '',
                    button_external: 0,
                    position       : 'right-center',
                    color          : 'light',
                    publish_now    : 1,
                    published_at   : null,
                    locale         : this.activeTab,
                };
                this.model.items.unshift(item);
                this.$toast.success(this.$t('slideshows.addItem'));
            },

            /**
             * Init Settings
             */
            initSettings() {
                this.locales.forEach((locale) => {
                    if (!this.model.settings[locale] || Array.isArray(this.model.settings[locale])) {
                        this.$set(this.model.settings, locale, {});
                    }
                    if (!this.model.settings[locale].autoplay) {
                        this.$set(this.model.settings[locale], 'autoplay', 0);
                    }
                    if (!this.model.settings[locale].pause_on_hover) {
                        this.$set(this.model.settings[locale], 'pause_on_hover', 0);
                    }
                    if (!this.model.settings[locale].space_between) {
                        this.$set(this.model.settings[locale], 'space_between', 30);
                    }
                    if (!this.model.settings[locale].slides_per_view) {
                        this.$set(this.model.settings[locale], 'slides_per_view', 1);
                    }
                    if (!this.model.settings[locale].loop) {
                        this.$set(this.model.settings[locale], 'loop', 0);
                    }
                    if (!this.model.settings[locale].effect) {
                        this.$set(this.model.settings[locale], 'effect', 'slide');
                    }
                    if (!this.model.settings[locale].direction) {
                        this.$set(this.model.settings[locale], 'direction', 'horizontal');
                    }
                    if (!this.model.settings[locale].speed) {
                        this.$set(this.model.settings[locale], 'speed', '5000');
                    }
                    if (!this.model.settings[locale].transition) {
                        this.$set(this.model.settings[locale], 'transition', '1000');
                    }
                });
            },

            /**
             * Save slideshow
             */
            save() {
                const params = {
                    id: this.id,
                    ...this.model,
                };
                return this.$services.ContentThemes.slideshowsSave(params).then(() => {
                    this.$toast.success(this.$t('messages.slideshow.save.success'));
                    this.fetch();
                }).catch((error) => this.handleError(error));
            },

            /**
             * Show preview of slideshow
             */
            showPreview() {
                const items = [];
                this.currentLocaleSlides.forEach((slide) => {
                    if (!slide.slideModel) {
                        return;
                    }
                    if (slide.active === 0) {
                        return;
                    }
                    if (slide.deleted_at && slide.active === undefined) {
                        return;
                    }
                    items.push(slide);
                });

                if (items.length) {
                    const params = {
                        items,
                        settings: this.model.settings[this.activeTab],
                        locale  : this.activeTab,
                    };
                    this.$refs.previewModal.open(params);
                } else {
                    this.$toast.error(this.$t('slideshows.empty_preview'));
                }
            },

            /**
             * Open delete modal
             *
             * @param payload
             */
            openDeleteModal(payload) {
                const isPublished = this.isSlidePublished(payload.index);
                this.$refs.deleteModal.open(payload, isPublished);
            },

            /**
             * Delete slide with id
             *
             * @param id {String}
             */
            doDelete(id) {
                this.model.items = this.model.items.filter((item) => item.id !== id);
            },

            /**
             * generate tabs based on locales and set the default active tab
             */
            generateTabs() {
                this.tabs = this.locales.map((locale) => ({
                    id   : locale,
                    title: locale,
                }));
                if (!this.activeTab) {
                    const [activeTab] = this.locales;
                    this.activeTab = activeTab;
                }
            },

            /**
             * Change current active tab
             *
             * @param tabId
             */
            onChangeTab(tabId) {
                this.activeTab = tabId;
            },

            /**
             * Check whether slideshow is saved or its on local
             *
             * @param index
             */
            isSlidePublished(index) {
                return !!this.model.items[index].published_at;
            },
        },

    };
</script>
