<template>
    <y-panel :title="$t('general.settings')">
        <div class="row">
            <div class="col">
                <y-form-field
                    v-model="model.autoplay"
                    type="checkbox"
                    name="slideshow.autoplay"
                    :label="$t('fields.slideshow.autoplay')"
                    :disabled="!permissions.can_edit"
                />
            </div>
            <div class="col">
                <y-form-field
                    v-model="model.loop"
                    type="checkbox"
                    name="slideshow.loop"
                    :label="$t('fields.slideshow.loop')"
                    :disabled="!permissions.can_edit"
                />
            </div>
        </div>

        <y-form-field
            v-model="model.pause_on_hover"
            type="checkbox"
            name="slideshow.pause_on_hover"
            :label="$t('fields.slideshow.pause_on_hover')"
            :disabled="!permissions.can_edit"
        />

        <div class="row">
            <div class="col">
                <y-form-field
                    v-model="model.space_between"
                    type="number"
                    :default="30"
                    name="slideshow.spaceBetween"
                    size="sm"
                    :readonly="!permissions.can_edit"
                />
            </div>
            <div class="col">
                <y-form-field
                    v-model="model.slides_per_view"
                    type="number"
                    :default="1"
                    name="slideshow.slidesPerView"
                    size="sm"
                    :readonly="!permissions.can_edit"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <y-form-field
                    v-model="model.effect"
                    type="select"
                    name="slideshow.effect"
                    size="sm"
                    :disabled="!permissions.can_edit"
                    :options="effects"
                />
            </div>
            <div class="col">
                <y-form-field
                    v-model="model.direction"
                    type="select"
                    name="slideshow.direction"
                    size="sm"
                    :disabled="!permissions.can_edit"
                    :options="directions"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <y-form-field
                    v-model="model.speed"
                    type="number"
                    :min="100"
                    :default="100"
                    name="slideshow.speed"
                    size="sm"
                    :readonly="!permissions.can_edit"
                />
            </div>
            <div class="col">
                <y-form-field
                    v-model="model.transition"
                    type="number"
                    :min="100"
                    :default="100"
                    name="slideshow.transition"
                    size="sm"
                    :readonly="!permissions.can_edit"
                />
            </div>
        </div>
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';

    export default {

        name: 'SlideshowSettings',

        components: {
            YFormField,
        },

        props: {
            /**
             * Input Value
             */
            value: Object,

            /**
             * List of permissions
             */
            permissions: Object,

            directions: Array,

            effects: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        watch: {
            /**
             * Emit input when model changes
             */
            model() {
                this.$emit('input', this.model);
            },

            /**
             * Watch for value changes
             *
             * @param newVal
             */
            value(newVal) {
                if (newVal !== this.model) {
                    this.$set(this, 'model', newVal);
                }
            },
        },

    };

</script>
