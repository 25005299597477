<template>
    <y-panel
        class="labels-sidebar"
        icon="md-folder-multiple"
        :title="$t('faqs.categories')"
    >
        <template
            v-if="canManage"
            slot="functions"
        >
            <y-button
                size="xs"
                tag="router-link"
                :to="folderTo"
            >
                {{ $t('button.manage') }}
            </y-button>
        </template>

        <ul v-if="labels">
            <template v-for="label in labels">
                <li :key="`side-label-${label.id}`">
                    <a
                        v-scroll-to="`#label-item-${label.id}`"
                        href="#"
                    >
                        {{ label.title }}
                    </a>
                </li>
            </template>
        </ul>
    </y-panel>
</template>

<script>
    export default {

        name: 'Sidebar',

        props: {
            /**
             * Array of labels to link
             */
            labels: Array,

            /**
             * Folder id
             */
            folders: Array,

            /**
             * User permissions
             */
            permissions: Object,
        },

        computed: {

            /**
             * Folder mange link
             *
             * @returns {{name: string}}
             */
            folderTo() {
                if (this.folders.length === 1) {
                    return {
                        name  : 'content-tagging-manage',
                        params: {
                            slug: this.folders[0],
                        },
                    };
                }

                return {
                    name: 'content-tagging-folders',
                };
            },

            /**
             * Can manage folders
             *
             * @returns {*|boolean}
             */
            canManage() {
                return this.folders && this.folders.length > 0 && this.permissions && this.permissions.can_manage_folders;
            },

        },

    };
</script>
