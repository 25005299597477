<template>
    <y-panel
        :title="slideTitle"
        :icon="permissions.can_edit ? 'md-menu' : null"
        :subtitle="subtitle"
        class="slide-item"
        collapsible
    >
        <template slot="functions">
            <y-button
                v-if="permissions.can_trash"
                size="xs"
                class="color-red"
                @click.native.prevent="doDelete"
            >
                {{ $t('button.delete') }}
            </y-button>

            <y-form-field
                v-if="shouldShowSwitch"
                v-model="active"
                type="checkbox"
                :name="`active-${keyHelper}`"
                size="sm"
                class="mb0"
                switch
                circular
                color="green"
                no-label
                @click.native.prevent="changeStatus"
            />
        </template>

        <y-row>
            <y-col size="4">
                <div :class="{disabled: disableFeidls}">
                    <y-form-uploader
                        name="slide"
                        small
                        :accepted-files="['jpg', 'jpeg', 'png', 'gif']"
                        :max-files="1"
                        :files="files"
                        @success="addSlide"
                        @remove="removeSlide"
                    />
                </div>

                <y-form-field
                    v-model="model.has_content"
                    type="checkbox"
                    :name="`has_content-${keyHelper}`"
                    :label="$t('fields.has_content')"
                    switch
                    size="sm"
                    circular
                    :disabled="disableFeidls"
                />

                <y-form-field
                    v-model="model.has_button"
                    type="checkbox"
                    :name="`has_button-${keyHelper}`"
                    :label="$t('fields.has_button')"
                    switch
                    size="sm"
                    circular
                    :disabled="disableFeidls"
                />
            </y-col>
            <y-col>
                <template v-if="model.has_content">
                    <y-form-field
                        v-model="model.title"
                        type="text"
                        name="title"
                        size="sm"
                        :readonly="disableFeidls"
                    />
                    <y-form-field
                        v-model="model.title2"
                        type="text"
                        name="title2"
                        size="sm"
                        :readonly="disableFeidls"
                    />
                    <y-form-field
                        v-model="model.text"
                        type="textarea"
                        name="text"
                        size="sm"
                        rows="3"
                        :readonly="disableFeidls"
                    />

                    <hr>
                </template>

                <template v-if="model.has_button">
                    <y-form-field
                        v-model="model.button_title"
                        type="text"
                        name="button_title"
                        size="sm"
                        :readonly="disableFeidls"
                    />
                    <y-form-field
                        v-model="model.button_link"
                        type="text"
                        name="button_link"
                        size="sm"
                        :readonly="disableFeidls"
                    />

                    <y-form-field
                        v-model="model.button_external"
                        type="checkbox"
                        :name="`button_external-${keyHelper}`"
                        size="sm"
                        :label="$t('fields.button_external')"
                        :disabled="disableFeidls"
                    />

                    <hr>
                </template>

                <template v-if="model.has_content || model.has_button">
                    <y-row>
                        <y-col>
                            <y-form-field
                                v-model="model.position"
                                type="select"
                                name="position"
                                :options="positionOptions"
                                :disabled="disableFeidls"
                            />
                        </y-col>
                        <y-col>
                            <y-form-field
                                v-model="model.color"
                                type="select"
                                name="color"
                                :options="colorOptions"
                                :disabled="disableFeidls"
                            />
                        </y-col>
                    </y-row>

                    <hr>
                </template>

                <y-form-field
                    v-model="model.publish_now"
                    type="checkbox"
                    :name="`publish_now-${keyHelper}`"
                    :label="$t('fields.publish_now')"
                    :disabled="disableFeidls"
                />
                <y-form-date-picker
                    v-if="!model.publish_now"
                    v-model="model.published_at"
                    type="datetime"
                    name="published_at"
                    input-format="jYYYY/jM/jD - HH:mm"
                    validation="'required'"
                    :readonly="disableFeidls"
                />
            </y-col>
        </y-row>
    </y-panel>
</template>

<script>
    import { YFormField, YFormUploader, YFormDatePicker } from '@deps';
    import { truncate, generateId } from '@nodes/helpers/string';

    export default {

        name: 'SlideshowItem',

        components: {
            YFormField,
            YFormUploader,
            YFormDatePicker,
        },

        props: {
            /**
             * Input Value
             */
            value: Object,

            /**
             * Item index
             */
            index: Number,

            /**
             * List of permissions
             */
            permissions: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                keyHelper: generateId(),

                model: this.value || {},

                active   : !this.value.deleted_at,
                collapsed: !!this.value.deleted_at,

                positionOptions: [
                    {
                        label: this.$t('fields.slideshow_positions.center_top'),
                        value: 'center-top',
                    },
                    {
                        label: this.$t('fields.slideshow_positions.center_center'),
                        value: 'center-center',
                    },
                    {
                        label: this.$t('fields.slideshow_positions.center_bottom'),
                        value: 'center-bottom',
                    },
                    {
                        label: this.$t('fields.slideshow_positions.right_top'),
                        value: 'right-top',
                    },
                    {
                        label: this.$t('fields.slideshow_positions.right_center'),
                        value: 'right-center',
                    },
                    {
                        label: this.$t('fields.slideshow_positions.right_bottom'),
                        value: 'right-bottom',
                    },
                    {
                        label: this.$t('fields.slideshow_positions.left_top'),
                        value: 'left-top',
                    },
                    {
                        label: this.$t('fields.slideshow_positions.left_center'),
                        value: 'left-center',
                    },
                    {
                        label: this.$t('fields.slideshow_positions.left_bottom'),
                        value: 'left-bottom',
                    },
                ],

                colorOptions: [
                    {
                        label: this.$t('fields.slideshow_colors.light'),
                        value: 'light',
                    },
                    {
                        label: this.$t('fields.slideshow_colors.dark'),
                        value: 'dark',
                    },
                ],
            };
        },

        computed: {
            /**
             * Return an array of already-uploaded files
             */
            files() {
                return this.model.slideModel ? [this.model.slideModel] : [];
            },

            /**
             * Return slide title
             */
            slideTitle() {
                if (this.model.has_content && this.model.title !== '') {
                    return `${this.$t('slideshows.slide')}: ${truncate(this.model.title)}`;
                }
                return this.$t('slideshows.slide');
            },

            /**
             * Get subtitle
             */
            subtitle() {
                return this.active ? '' : `(${this.$t('general.status.inactive')})`;
            },

            /**
             * Check item is in loading mode
             */
            isLoading() {
                return this.$wait.is(`slideshow-item-${this.keyHelper}`);
            },

            /**
             * Check if should show switch
             */
            shouldShowSwitch() {
                if (this.active && this.permissions.can_trash) {
                    return true;
                }
                if (!this.active && this.permissions.can_restore) {
                    return true;
                }

                return false;
            },

            /**
             * Determine whether if slide is published
             */
            isPublished() {
                return !!this.model.published_at;
            },

            /**
             * Determine whether if slide fields should be disabled
             *
             * @returns {boolean}
             */
            disableFeidls() {
                if (this.permissions.can_edit) {
                    return false;
                }
                if (this.permissions.can_create && !this.isPublished) {
                    return false;
                }
                return true;
            },

        },

        watch: {
            /**
             * Emit input when model changes
             */
            model() {
                this.$emit('input', this.model);
            },

            /**
             * Watch active and add it to model
             */
            active() {
                this.model.active = this.active ? 1 : 0;
            },

        },

        methods: {

            /**
             * Do delete
             */
            doDelete() {
                this.$emit('delete', {
                    id   : this.model.id,
                    index: this.index,
                });
            },

            /**
             *
             */
            startLoading() {
                this.$wait.start(`slideshow-item-${this.keyHelper}`);
            },

            /**
             *
             */
            endLoading() {
                this.$wait.end(`slideshow-item-${this.keyHelper}`);
            },

            /**
             * Change status of item
             */
            changeStatus() {
                const type = this.active ? 'trash' : 'restore';
                if (this.model.new) {
                    this.active = !this.active;
                    this.$toast.success(this.$t(`messages.slideshow.${type}.success`));
                } else {
                    this.startLoading();
                    this.$services[type]('content-post', this.value.id).then(() => {
                        this.active = !this.active;
                        this.$toast.success(this.$t(`messages.slideshow.${type}.success`));
                    }).catch((error) => {
                        this.handleError(error);
                    }).finally(() => this.endLoading());
                }
            },

            /**
             * Add slide image to model
             *
             * @param file
             */
            addSlide(file) {
                const slide = {
                    link     : file.link,
                    versions : file.versions,
                    mime_type: file.mime_type,
                    id       : file.id,
                };
                this.$set(this.model, 'slide', [slide.id]);
                this.$set(this.model, 'slideModel', slide);
            },

            /**
             * Remove slide image from model
             */
            removeSlide() {
                this.$set(this.model, 'slide', null);
                this.$set(this.model, 'slideModel', null);
                this.$set(this.model, 'slide-image', null);
            },

        },

    };
</script>
